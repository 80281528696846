<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Holders" subtitle="Manage the holders of this ticket." back="Convention.Schedule.Tickets.Edit" :backParams="$route.meta.backParams" :readonly="$authorised('con/schedule/access', 'read')">

	</app-content-head>

	<app-content-filters>

		<app-content-filters-search v-model="filter.search" />

	</app-content-filters>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no holders found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Reference" />
				<app-table-column text="Name" />
				<app-table-column text="Date Acquired" />
				<app-table-column text="Date Scanned" />
				<app-table-column />

			</app-table-head>

			<app-table-body :sortable="sortedIndex" v-on:sorted="onSorted">

				<app-table-row v-on:click="onEditClick(item.id)" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.badge.reference" />
					<app-table-cell-text :text="item.badge.name" />
					<app-table-cell-text :text="item.created | localDate('MM/DD/YYYY h:mma')" />
					<app-table-cell-text v-if="item.scanned" :text="item.scanned | localDate('MM/DD/YYYY h:mma')" />
					<app-table-cell-text v-if="!item.scanned" :can-empty="true" />
					<app-table-cell-select :active="isSelected(item.id)" v-on:click="onSelectToggle(item.id)" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			pagination: {
				perpage: 100000
			},
			layout: '100px auto 200px 200px 24px',
			endpoint: 'convention/schedule/ticket/:ticket/holders',
			live: 'convention/schedule/ticket/:ticket/holders'
		}

	}

}

</script>

<style scoped>

</style>